
.Nav.Link .button {
    background-color: #3B8FF0;
}

.Signup {
    color:rgb(255, 255, 255);
}

.Logo {
    display: flex;
    margin-top: 6px;
}

.LogInButton{
    display: flex;
    font-size: 15px;
    padding:7px;
    border-radius: 6px;
    color:white;
    background-color: #3B8FF0;
    padding-left: 18px;
    padding-right: 18px;
    margin-right:-6px;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.LogInButton:hover {
    cursor: pointer;
    color: white;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #347fd4;
}

.LogInButtonSelected{
    display: flex;
    font-size: 15px;
    padding:7px;
    border-radius: 6px;
    color:white;
    background-color: #347fd4;
    padding-left: 18px;
    padding-right: 18px;
    margin-right:-6px;
}


.RegisterButton{
    display: flex;
    font-size: 15px;
    padding:6px;
    border-radius: 6px;
    border-style: solid;
    border-color: #2CA874;
    border-width: 1px;
    color:#2CA874;
    padding-left: 18px;
    padding-right: 18px;
    margin-right:-6px;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.RegisterButton:hover {
    cursor: pointer;
    color: white;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #2CA874;
}

.RegisterButtonSelected{
    display: flex;
    font-size: 15px;
    padding:6px;
    border-radius: 6px;
    border-style: solid;
    border-color: #2CA874;
    border-width: 1px;
    background-color: #228059;
    color:#ffffff;
    padding-left: 18px;
    padding-right: 18px;
    margin-right:-6px;
}

.TLLogo {
    width:37px;
    height:37px;
    margin-right:7px;
    margin-left:0px;
}









.TopbarLinksOut {
    padding-right:0px;
    height:50px;
}

.TopBarOut {
    height: 110px;
    width: 72%;
    margin: auto;
}

.TopbarLinksOut #active {
    color:rgb(0, 0, 0);
}

.Nav.Link .button {
    background-color: #6da3e2;
}

.Signup {
    color:rgb(255, 255, 255);
}

.TLLogo {
    width:37px;
    height:37px;
    margin-right:7px;
    margin-left:0px;
    margin-top:-1.5px;
}

.TopImg {
    width:auto;
}
