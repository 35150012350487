.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 90vw !important;
    max-width: 1400px;
    height: auto !important;
    margin-top: 3vh;
}

.passPrompt {
    background-color: rgb(255, 255, 255);
    padding:30px;
    text-align: center;
    border-radius: 10px;
    margin:auto;
    margin-top: 20vh;
    box-shadow: 0px 0px 100px purple;
    background-image: linear-gradient(30deg, rgb(255, 212, 212), rgb(221, 221, 255));
    opacity: 90%;
}

.PdfDoc{
    box-shadow: 0px 20px 100px purple;
}

.nextPage {
    position:fixed;
    top:45%;
    right:1%;
    color:white;
}

.nextPage:hover {
    cursor: pointer;
    color:rgb(188, 188, 188);
}

.lastPage {
    position:fixed;
    top:45%;
    left:1%;
    color:white;
}

.lastPage:hover {
    cursor: pointer;
    color:rgb(188, 188, 188);
}