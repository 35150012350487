#TortPlaintiffRow {
    background-color: white;
    display: flex;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(200, 200, 200);
    width: stretch;
    height:50px;
    border-radius: 5px;
    margin-bottom: 6px;
    overflow-x: hidden;
    overflow-y: hidden;
}

#TortPlaintiffRow.expanded {
    height: fit-content;
}

.AccordionContent {
    border-top-style: solid;
    border-top-color: rgb(210, 210, 210);
    border-width: 1px;
    padding-top:10px;
    width:inherit
}

.CardContent {
    width: inherit;
}

.TopContent {
    display:flex;
    height:50px;
    align-items: center;
    justify-content: center;
    padding:10px;
}

.TopContent:hover{
    cursor: pointer;
}

#ExpandIconPH {
    margin-left: 20px;
    transition: 0.3s ease-in-out;
}

#ExpandIconPH.expanded {
    transform: rotateX(180deg);
    transition: 0.3s ease-in-out;
}

.PCheck {
    transform: scale(1.2);
}

.PCheck:hover{
    cursor: pointer;
}

.LittleBox{
    background-color: rgb(232, 232, 232);
    padding: 2px;
    padding-left:6px;
    padding-right: 6px;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(210, 210, 210);
    margin-top: -2.5px;
}

.EditP{
    margin-left: auto;
    margin-right: 15px;
    display:flex;
}

.EditP:hover{
    cursor: pointer;
}

.DeleteP{
    display: flex;
    background-color: rgb(225, 107, 107);
    border-radius: 10px;
    color:white;
    padding:3px;
    padding-left: 6px;
    padding-right: 6px;
    margin-right: 5px;
}

.DeleteP:hover{
    cursor: pointer;
    background-color: rgb(197, 93, 93);
}

.UploadFiles{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(233, 233, 233);
    border-radius: 3px;
    padding:5px;
    margin-top: -5px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(95, 95, 95);
    height:33px;
    color:black;
    transform: scale(0.86);
}

.UploadFiles:hover{
    cursor: pointer;
    background-color: rgb(221, 221, 221);
}

.FilesP {
    display: flex;
    background-color: rgb(232, 232, 232);
    border-radius: 10px;
    padding: 3px;
    padding-left: 6px;
    padding-right: 6px;
    margin-right: 5px;
}

.FilesP:hover {
    cursor: pointer;
    background-color: rgb(222, 222, 222);
}

.FilesModal {
    position:relative;
    left:96%;
    top:25%;
    margin-top:105px;
    margin-left:-300px;
    z-index: 999;
    width: 300px;
    height: 250px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgb(207, 207, 207);
}

.FilesTop {
    border-bottom-style: solid;
    border-width: 1px;
    border-color: rgb(212, 212, 212);
    padding: 10px;
}

.FilesOverlay {
    position: relative;
    right:200%;
    top:-50%;
    background-color: white;
    opacity: 0%;
    width: 100vw;
    height:400px;
}

.DocRow{
    border-radius: 5px;
    background-color: rgb(237, 237, 237);
    margin:5px;
    padding:5px;
    display: flex;
}

.DocRow:hover{
    cursor: pointer;
    background-color: rgb(225, 225, 225);
}

.LinkOverlay{
    position: relative;
    margin-left: -250px;
    left:86%;
    background-color: rgb(255, 255, 255);
    opacity: 0%;
    width:250px;
    height:25px;
}

.FilesList{
    overflow-y: scroll;
    height:200px;
}

.DeleteFile{
    margin-left: auto;
    border-radius: 100px;
    background-color: rgb(235, 133, 133);
}

.DeleteFile:hover{
    box-shadow: 0px 0px 10px rgb(235, 133, 133);
}

.LejeuneCardPH{
    position:relative;
}
.LejeuneCard{
    position:absolute;
    right:1%;
    text-align: left;
    margin-right: -20px;
    margin-top: 15px;
    background-color: white;
    height:360px;
    overflow-y: scroll;
    width: 550px;
    padding-left:20px;
    padding-right: 20px;
    padding-bottom: 160px;
    padding-top:10px;
    transform: scale(0.9);
    border-style: solid;
    border-width: 1px;
    border-color: rgb(167, 0, 0);
}

.AreYouSure {
    z-index: 200;
    background-color: white;
    opacity: 90%;
    position: relative;
    width: 300px;
    height: 300px;
}

.YesBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
    background-color: rgb(243, 84, 84);
    width:100px;
    height: 30px;
    border-radius: 5px;
}

.YesBtn:hover{
    cursor: pointer;
    background-color: rgb(245, 32, 32);
}

.NoBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgb(201, 201, 201);
    width: 100px;
    height: 30px;
    border-radius: 5px;
}

.NoBtn:hover {
    cursor: pointer;
    background-color: rgb(174, 174, 174);
}

.IntakeOverlay{
    position:absolute;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
}

.IntakeModal {
    position: relative;
    left: 87%;
    top: 25%;
    margin-top: 105px;
    margin-left: -350px;
    z-index: 999;
    width: 350px;
    height: 160px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgb(207, 207, 207);
}