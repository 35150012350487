.PhoneArea {
    align-items: start;
    justify-content: space-around;
}

.PhoneArea img{
    margin: 0px;
}

.CloseRegister{
    float:right;
    margin-right:-10vh;
}

.CloseRegister:hover{
    cursor:pointer;
}

.link2 {

}

.link2:hover{
    cursor:pointer;
}

.SignupLoading {
    display:flex;
    justify-content: center;
    color:antiquewhite;
    margin-top: 200px;
}

.overlayP {
    background-color:white;
    opacity: 80%;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.PopContent {
    opacity: 100%;
    background-color: rgb(255, 255, 255);
    width: 590px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding:10px;
    box-shadow: 0px 0px 5px rgb(196, 196, 196);
    border-radius:6px;
    z-index:10000;
}

.PopCard {
    height: 640px;
    overflow-y: scroll;
    width:stretch;
}