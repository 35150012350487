.SharedListFinal {
    padding-left:20px;
    padding-right:20px;
    height:380px;
    overflow-y: scroll;
}

.SharedRow {
    background-color: white;
    display: flex;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(200, 200, 200);
    width: stretch;
    padding: 13px;
    border-radius: 5px;
    margin-bottom:6px;
    align-items: center;
    min-height: 67px;
}

.Remove {
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    margin-left:auto;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.Remove:hover {
    color: rgb(151, 151, 151);
    cursor: pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.actions {
  margin-left:auto;
}