.CaseInfo {
    display:flex;
}

.InfoColumn {
    margin:auto;
    margin-top:0;
    width:49.2%;
    height: fit-content;
}

.CardHeight {
    min-height: 560px;
}

.SharedRow {
    background-color: white;
    display: flex;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(200, 200, 200);
    width: stretch;
    padding: 13px;
    border-radius: 5px;
    margin-bottom: 6px;
}

.Remove {
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    margin-left:auto;
}

.Remove:hover {
    color: rgb(151, 151, 151);
    cursor: pointer;
}

.InfoEdit {
    padding:4px;
    display:flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom:4px;
    margin-top:-4px;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.InfoEdit:hover {
    cursor:pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.InfoTop {
    display:flex;
}

.InfoTopItems {
    display: flex;
    align-items: center;
}

.ModifyOpts {
    display:flex;
    margin:auto;
}

.DraggableParent {
    z-index: 9999;
    position: fixed;
    right:10%;
    top:8%;
}
.ModifyOptsDraggable {
    display:flex;
    z-index: 9999;
    top:20%;
    margin:auto;
    background-color: white;
    border-radius: 100px;
    padding:20px;
    padding-left:30px;
    padding-right:30px;
    box-shadow: 0px 0px 5px rgb(213, 213, 213);
    width:268px;
}

.SaveBtn {
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(158, 183, 242);
    border-radius: 50px;
    padding:5px;
    width:35px;
    height:35px;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.SaveBtn:hover {
    background-color: rgb(143, 166, 219);
    cursor:pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.CancelBtn {
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(230, 165, 165);
    border-radius: 50px;
    padding:5px;
    width:35px;
    height:35px;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.CancelBtn:hover {
    background-color: rgb(205, 146, 146);
    cursor:pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}


.infoRow{
    display: flex;
    border-color: #cfcfcf;
    border-bottom-style:solid;
    border-width: 1px;
    padding:10px;
    color: #7f7f7f;
}

.infoRowL{
    color: #7f7f7f;
    display: flex;
    padding:10px;
}

.infoRowTB{
    color: #7f7f7f;
    display: flex;
    padding:10px;
    display: flex;
    border-color: #cfcfcf;
    border-bottom-style:solid;
    border-top-style:solid;
    border-width: 1px;
    padding:10px;
    color: #7f7f7f;
}


.infoRowX{
    display: flex;
    padding:6px;
    width:50%;
    margin-left: 20px;
}

.coaList{
    padding-bottom:10px;
    margin-top:-10px;
}

.dragIcon{
    position:fixed;
    right:3%;
    top:31%;
}

.dragIcon:hover{
    cursor:grab;
}

.dragIcon:active{
    cursor:grabbing;
}

.DoneBtn {
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(225, 225, 225);
    border-radius: 5px;
    padding:5px;
    width:100px;
    height:35px;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.DoneBtn:hover {
    background-color: rgb(179, 179, 179);
    cursor:pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}