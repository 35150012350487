.About{
    background-color: rgb(255, 255, 255);
}

.abtimg{
  position: relative;
  height:auto;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  width: 100vw;
}