.SharedList {
    padding-left:20px;
    padding-right:20px;
}

.PlaintiffPageWrapper {
    justify-content: center;
    margin:auto;
    flex-wrap: wrap;
    width: 93%;
} 

.PlaintiffMain {
    height: 80vh;
    margin-bottom: 50px;
    background-color: white;
    border-width:1px;
    border-style: solid;
    border-color:rgb(214, 214, 214);
    border-radius: 5px;
    overflow-y: hidden;
}

.PlaintiffScroll {
    padding-left:10px;
    padding-right: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding-top:10px;
    height:71vh;
    overflow-y: scroll;
    background-color: rgb(244, 244, 244);
    box-shadow: inset 0px 0px 5px rgb(205, 205, 205);
}

.SharedRow {
    background-color: white;
    display: flex;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(200, 200, 200);
    width: stretch;
    padding: 13px;
    border-radius: 5px;
    margin-bottom:6px;
}

input[type='file'] {
    color: transparent;
}

.Remove {
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    margin-left:auto;
}

.Remove:hover {
    color: rgb(151, 151, 151);
    cursor: pointer;
}

.overlayP {
    background-color:white;
    opacity: 90%;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.PopContent {
    opacity: 100%;
    background-color: rgb(255, 255, 255);
    width: 590px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding:10px;
    box-shadow: 0px 0px 5px rgb(196, 196, 196);
    border-radius:6px;
    z-index:10000;
}

.PopCard {
    height: 640px;
    overflow-y: scroll;
    width:stretch;
}

.IPopContent {
    position:absolute;
    opacity: 100%;
    background-color: rgb(255, 255, 255);
    width: 230px;
    right: -11%;
    top: 43%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 5px rgb(196, 196, 196);
    border-radius:6px;
    z-index:10000;
    padding-bottom: 13px;
}

.IPopContent2 {
    position: absolute;
    opacity: 100%;
    background-color: rgb(255, 255, 255);
    width: 230px;
    right: 4%;
    top: 43%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 5px rgb(196, 196, 196);
    border-radius: 6px;
    z-index: 10000;
    padding-bottom: 13px;
}


.ITop{
    text-align: center;
    padding-bottom:10px;
    padding-top:10px;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: rgb(223, 223, 223);
    margin-bottom: 10px;
}

.IBtn{
    padding-left:10px;
    padding-right:10px;
    padding-bottom:5px;
    padding-top:5px;
    display: flex;
}

.IBtn:hover{
    cursor: pointer;
    background-color: rgb(241, 241, 241);
}

.overlayI {
    background-color:white;
    opacity: 0%;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.Close {
    float: right;
}

.Close:hover {
    color:rgb(90, 90, 90);
    cursor:pointer;
}


.PlaintiffExpand {
    background-color: rgb(222, 222, 222);
    border-radius: 3px;
    padding:4px;
    display:flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left:15px;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.PlaintiffExpand:hover {
    cursor:pointer;
    background-color: rgb(183, 183, 183);
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.SendIntake {
    background-color: rgb(222, 222, 222);
    border-radius: 3px;
    padding:4px;
    display:flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left:15px;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.SendIntake:hover {
    cursor:pointer;
    background-color: rgb(183, 183, 183);
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.ViewDocs {
    background-color: rgb(222, 222, 222);
    border-radius: 3px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: auto;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.ViewDocs:hover {
    cursor: pointer;
    background-color: rgb(183, 183, 183);
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.PlaintiffRow {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(200, 200, 200);
    width: stretch;
    padding: 13px;
    border-radius: 5px;
    margin-bottom:6px;
}

.PEditTop {
    padding-top:5px;
    display: flex;
}

.PlaintiffEdit {
    background-color: rgb(222, 222, 222);
    border-radius: 3px;
    padding:4px;
    display:flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left:10px;
    margin-top:-2px;
    margin-bottom:2px;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.PlaintiffEdit:hover {
    cursor:pointer;
    background-color: rgb(183, 183, 183);
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.SaveBtn1 {
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(212, 212, 212);
    border-radius: 4px;
    padding:5px;
    width:90px;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.SaveBtn1:hover {
    background-color:rgb(178, 178, 178);
    cursor:pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.CancelBtn1 {
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(212, 212, 212);
    border-radius: 4px;
    padding:5px;
    width:90px;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.CancelBtn1:hover {
    background-color: rgb(178, 178, 178);
    cursor:pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.DelBtnNotes {
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(230, 165, 165);
    border-radius: 4px;
    padding:5px;
    width:25px;
    height:25px;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.DelBtnNotes:hover {
    background-color: rgb(205, 146, 146);
    cursor:pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}


.SaveBtnDisabled {
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(183, 198, 232);
    border-radius: 4px;
    color:rgb(145, 145, 145);
    padding:5px;
    width:90px;
}

.PlaintiffsTop {
    text-align: center;
    display: flex;
    margin: auto;
}

.PlaintiffsTop .Add {
    background-color: rgb(35, 172, 47);
    border-radius: 2px;
    padding: 3px;
    padding-top: 2px;
    padding-left:4px;
    padding-right:4px;
    margin-left: 20px;
    margin-top: -4px;
    margin-bottom:20px;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.PlaintiffsTop .Add:hover {
    background-color: rgb(32, 141, 41);
    cursor: pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.PAccordion {
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-width: 1px;
}

.AddPlaintiff {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0, 182, 55);
    padding: 5px;
    border-radius: 3px;
    width: 30px;
    height: 30px;
}

.AddPlaintiff:hover{
    background-color: rgb(0, 160, 55);
    cursor: pointer;
}

.PlaintiffPageTop {
    display: flex;
    margin-top:-10px;
    margin-bottom: 15px;
}

.SortPlaintiffs {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(222, 222, 222);
    border-radius: 3px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 10px;
    height:40px;
}

.SortPlaintiffs:hover {
    cursor: pointer;
    background-color: rgb(202, 202, 202);
}

.SortMenu {
    position:absolute;
    top:0%;
    margin-top: 30px;
    margin-left: -290px;
    background-color: white;
    border-radius: 5px;
    width:300px;
    height:fit-content;
    box-shadow: 0px 0px 5px rgb(202, 202, 202);
    z-index: 9999;
    left: 68%;
}

.SortMenuOverlay {
    background-color: white;
    opacity: 0%;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.SortMenuTop{
    padding: 10px;
    text-align: center;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: rgb(212, 212, 212);
}

.SortInput {
    width:80%;
    padding-left: 10px;
    margin-top: 7px;
    border-color: rgb(186, 186, 186);
    border-style: solid;
    border-width:1px;
    height:35px;
    border-radius: 5px;
}

.SortMenuOpts {
    padding: 15px;
}

.PCheck {
    transform: scale(1.2);
}

.PCheck:hover {
    cursor: pointer;
}

.OverviewBtn{
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(216, 216, 216);
    width: fit-content;
    display: flex;
    padding: 5px;
    border-radius: 5px;
    margin-left: 10px;
}

.OverviewBtn:hover{
    cursor: pointer;
    background-color: rgb(243, 243, 243);
}

.PlaintiffOverview{
    overflow-y: scroll;
    background-color: rgb(255, 255, 255);
    width: fit-content;
    max-width: 80vw;
    overflow-x:scroll;
    height:80vh;
    position: absolute;
    top:10%;
    left:11%;
    box-shadow: 0px 0px 5px rgb(221, 221, 221);
    border-style: solid;
    border-width: 1px;
    border-color: rgb(189, 189, 189);
    border-radius: 3px;
    z-index: 999999;
}

.OverviewOverlay{
    background-color: white;
    opacity: 90%;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 999;
}

.OverviewOverlay:hover{
    cursor: pointer;
}

.OverviewTop{
    display: flex;
    background-color: rgb(222, 222, 222);
    height:40px;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: rgb(171, 171, 171);
}

.GridItem{
    border-right-style: solid;
    border-width: 1px;
    border-color: rgb(171, 171, 171);
    display:flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: hidden;
    width:200px;
}

.GridItemL {
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
}

.OverviewRow{
    display: flex;
    background-color: rgb(255, 255, 255);
    height: 40px;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: rgb(171, 171, 171);
}

.CloseOverview{
    position: absolute;
    right:2%;
    top:10%;
    display: flex;
}

.CloseOverview:hover{
    cursor: pointer;
}

.SortMenuPH {
    position: relative;
    width: 0px;
    height: 0px;
}

.PlaintiffNotes {
    background-color: rgb(255, 255, 255);
    width: fit-content;
    max-width: 80vw;
    overflow-y: hidden;
    height: 700px;
    width: 50vw;
    margin-top:-40vh;
    margin-left:-25vw;
    padding:20px;
    position: relative;
    box-shadow: 0px 0px 5px rgb(189, 189, 189);
    border-radius: 2px;
    z-index: 999999;
}

.NotesOverlay {
    background-color: white;
    opacity: 90%;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0%;
    left: 0%;
}

.NotesOverlay:hover {
    cursor: pointer;
}

.MiddleAnchor{
    position:absolute;
    width:0px;
    height:0px;
    top:50%;
    left:50%;
}

.SaveNotes {
    text-align: center;
    background-color: rgb(52, 182, 52);
    color: white;
    margin:auto;
    padding:5px;
    padding-left: 15px;
    padding-right:25px;
    border-radius: 4px;
}

.SaveNotes:hover{
    background-color: rgb(46, 159, 46);
    cursor: pointer;
}

.RichTextArea{
    height:60%;
}

.NoteRow {
    width:100%;
    background-color: rgb(243, 243, 243);
    margin-top:5px;
    height:40px;
    display: flex;
    align-items: center;
    padding: 5px;
    overflow-y: hidden;
}

.NotesList {
    padding-top: 10px;
    height:90%;
    overflow-y: scroll;
}

.OpenNote {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(216, 216, 216);
    border-radius: 4px;
    padding: 5px;
    width: fit-content;
    height: 25px;
    margin-right: 20px;
}

.OpenNote:hover {
    background-color: rgb(188, 188, 188);
    cursor: pointer;
}

.AreYouSureModal {
    position:absolute;
    background-color: white;
    border-radius: 5px;
    width: 300px;
    height: 120px;
    box-shadow: 0px 0px 5px rgb(196, 196, 196);
    top:20px;
    left:50%;
    transform: translate(-50%, 0%);
    text-align: center;
    padding: 10px;
}

.ClosePNotes {
    margin-left: auto;
    margin-bottom: 10px;
}

.ClosePNotes:hover{
    cursor: pointer;
    color: gray;
}