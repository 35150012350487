.AccountPage {
  margin-left:20px;
  margin: 0 auto;
  /* flex-wrap: wrap; */
}

.AccountContainer {
  background-color: #ffffff;
  width:fit-content;
  height: fit-content;
  border-radius: 8px;
  margin-right:20px;
  margin:auto;
  min-height: 300px;
}

.AccountEditors{
  display:flex;
}

.List {
  text-align: left;
  height: 300px;
  overflow-y: scroll;
  background-color: #eaeaea;
}

.fuser {
  display:inline-block;
  min-width:stretch;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #fafafa;
  margin-top:9px;
  padding:10px;
  min-height:55px;
  max-height:55px;
  font-size: 20px;
  border-radius: 10px;
}

.fuser2 {
  background-color: #e8e8e8;
  margin:auto;
  padding:10px;
  min-height:55px;
  max-height:55px;
  max-width: 100%;
  font-size: 20px;
  border-radius: 10px;
  text-align: center;
}

.actions {
  float:right;
}

.fuser button {
  align-items: center;
  appearance: none;
  background-color: #3EB2FD;
  background-image: linear-gradient(1deg, #4F58FD, #149BF3 99%); 
  background-size: calc(100% + 20px) calc(100% + 20px);
  border-radius: 100px;
  border-width: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-flex;
  font-family: CircularStd,sans-serif;
  font-size: 1rem;
  height: auto;
  justify-content: center;
  line-height: 1.5;
  padding: 6px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background-color .2s,background-position .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.fuser button:active,
.fuser button:focus {
  outline: none;
}

.fuser button:hover {
  background-position: -20px -20px;
}

.fuser button:focus:not(:active) {
  box-shadow: rgba(40, 170, 255, 0.25) 0 0 0 .125em;
}

.fuser .button2 {
    align-items: center;
    appearance: none;
    background-color: #3EB2FD;
    background-image: linear-gradient(1deg, #fd4f4f, #f38b14 99%);
    background-size: calc(100% + 20px) calc(100% + 20px);
    border-radius: 100px;
    border-width: 0;
    box-shadow: none;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-flex;
    font-family: CircularStd,sans-serif;
    font-size: 1rem;
    height: auto;
    justify-content: center;
    line-height: 1.5;
    padding: 6px 20px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: background-color .2s,background-position .2s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: top;
    white-space: nowrap;
  }
  
.fuser .button2:active,
.fuser .button2:focus {
  outline: none;
}

.fuser .button2:hover {
  background-position: -20px -20px;
}

.fuser .button2:focus:not(:active) {
  box-shadow: rgba(40, 170, 255, 0.25) 0 0 0 .125em;
}

.TopBox {
  text-align: center;
  padding-right: auto;
  display: inline;
  align-items: center;
  justify-content: center;
  word-spacing: 100%;
}

.UserContainer .selection{
  float: right;
}

.UserContainer .selection:hover{
  cursor: pointer;
}

.Collapse:hover {
  cursor: pointer;
}

.Expand:hover {
  cursor: pointer;
}

.accountIcon {
  margin-right: 10px;
}

.PhoneUpdate {
}

.PhoneUpdate img{
  margin: 0px;
}

.errorMsg {
  text-align: center;
  margin-bottom:10px;
  color:#fd4f4f;
}

.rowData {
  display:flex;
}

.DataText {
  text-align: center;
  font-size: larger;
}

.AEditTop {
  padding-top:5px;
  display: flex;
  justify-content: center;
}

.AccountEdit {
  background-color: rgb(222, 222, 222);
  border-radius: 3px;
  padding:4px;
  display:flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left:10px;
  margin-top:-2px;
  margin-bottom:2px;
  -moz-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
}

.AccountEdit:hover {
  cursor:pointer;
  background-color: rgb(183, 183, 183);
  -moz-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
}

.infoRow{
  display: flex;
  border-color: #cfcfcf;
  border-bottom-style:solid;
  border-width: 1px;
  padding:10px;
  color: #7f7f7f;
}

.infoRowL{
  color: #7f7f7f;
  display: flex;
  padding:10px;
}