.MattersPage {
    margin-left:20px;
    margin: 0 auto;
    /* flex-wrap: wrap; */
}

.MattersContainer {
    display: flex;
    background-color: #ffffff;
    width: fit-content;
    height: fit-content;
    border-radius: 8px;
    margin-right:20px;
    margin:auto;
    min-height: 400px;
}

.MattersList {
    margin-left:10px;
    margin-right:10px;
    height:300px;
    overflow-y: scroll;
    width: 393px;
    padding: 5px;
}

.MattersList2 {
    margin-left:10px;
    margin-right:10px;
    height:300px;
    width: 393px;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top:70px;
}

.MattersListLoading {
    margin-left:10px;
    margin-right:10px;
    height:300px;
    overflow-y: scroll;
    width: 393px;
    background-color: #ececec;
    display:flex;
    padding-top:110px;
    justify-content: center;
}

.MatterRow {
    background-color: white;
    display: inline-block;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(200, 200, 200);
    width: stretch;
    padding: 6px;
    border-radius: 5px;
    margin-bottom:6px;
    padding-top:10px;
}

.MatterRow .Edit {
    float:right;
    background-color: rgb(179, 179, 179);
    border-radius: 2px;
    padding: 3px;
    margin-right: 2px;
    margin-top:-4px;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.Edit:hover {
    background-color: rgb(148, 148, 148);
    cursor: pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.MattersTop {
    text-align: center;
    display: flex;
    margin: auto;
    margin-bottom: -15px;
}

.MattersTop .Add {
    background-color: #3B8FF0;
    border-radius: 3px;
    padding: 3px;
    padding-top: 2px;
    padding-left:4px;
    padding-right:4px;
    margin-left: 20px;
    margin-top: -4px;
    margin-bottom:20px;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.MattersTop .Add:hover {
    background-color: #317bcf;
    cursor: pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.MattersTop .FSettings {
    display:flex;
    align-items: center;
    color: white;
    background-color:#3B8FF0;
    border-radius: 3px;
    padding: 3px;
    padding-top: 2px;
    padding-left:4px;
    padding-right:4px;
    margin-left: 20px;
    margin-top: -4px;
    margin-bottom:20px;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.MattersTop .FSettings:hover {
    background-color: #317bcf;
    cursor: pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}


.CreatePopup {
    margin: 20px;
    text-align: center;
    margin-bottom: 35px;
}

.BottomMatter {
    width:847px;
    margin:auto;
    padding-top:17px;
}

.BottomMatter .MattersList {
    margin-left:10px;
    margin-right:10px;
    height:200px;
    overflow-y: scroll;
    width: stretch;
    border-color: rgb(200, 200, 200);
    padding: 5px;
}

.CreateContainer {
    width:600px;
    margin: auto;
}

.CloseContainer {
    margin-left:auto;
}

.CloseContainer:hover {
    cursor: pointer;
    color:rgb(91, 91, 91)
}

.Cause {
    display: flex;
    border-style: solid;
    padding:7px;
    border-width: 1px;
    width:70%;
    margin-left:55px;
    margin-bottom:-1px;
    border-color: rgb(182, 182, 182);
}

.RemCause {
    border-radius: 100%;
    margin-left:auto;
}

.RemCause:hover {
    background-color: rgb(198, 198, 198);
    cursor:pointer;
}

.loadRight {
    
}

.MyMattersPage{
    /* width:100vw;
    margin-left:-225px;
    padding-left:225px;
    margin-right:80px; */
    width:100%;
    display:flex;
    justify-content:center;
    margin-left: 16px;
}

.MatterBlock {
    background-color: white;
    display: inline-block;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(224, 224, 224);
    width: 220px;
    height:230px;
    border-radius: 5px;
    margin-bottom:15px;
    margin-right:25px;
    margin-top:10px;
}

.MatterBlock:hover {
    background-color: white;
    box-shadow: 0px 0px 10px rgb(232, 232, 232);
    cursor: pointer;
}

.MatterLine {
    color: rgb(174, 174, 174);
    z-index: -20;
}

.Steady {
    color:rgb(93, 93, 213);
}

.Urgent {
    color:rgb(207, 65, 65);
}

.Complete {
    color:rgb(121, 121, 121);
}

.MattersRows{
    width:100%;
    margin-left:20px;
}

.InfoTab{
   border-top-style: solid;
   border-top-width: 1px;
   margin-top:13px;
   padding-top:18px;
   border-color: rgb(225, 225, 225);
}