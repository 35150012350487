.SidebarHome {
    top:0%;
    position:fixed;
    left:0;
    z-index: 0;
    text-align: left;
    padding-top: 40px;
    padding-left: 0px;
    height:100vh;
    background-color: #ffffff;
    text-align: left;
    padding-top: 30px;
    padding-left: 0px;
    min-width: 225px;
    max-width: 225px;
    padding-top:100px;
}

.PlaceHolder1 {
    text-align: left;
    padding-top: 18px;
    padding-left: 0px;
    max-height:100%;
    width: 225px;
    background-color: rgb(255, 255, 255);
}


.Top {
    justify-content: center;
    display:inline;
}

.Collapse {
    float: right;
    padding-right: 20px;
    padding-bottom: 20px;
}


.Top2 {
    justify-content: center;
    display:inline;
}

.Expand {
    float: right;
    padding-right: 24px;
    padding-bottom: 20px;
}


body {
    margin: 0;
    padding: 0;
}

.Sidebar h1 {
    resize: none;
    font-size: 2em;
    resize: none;
}

.Sidebar p {
    padding-left: 20px;
}

img {
    width: 200px;
    height: 45px;
    align-items: center;
    margin-left: 40px;
}

.SidebarListHome {
    height: auto;
    padding: 0;
    width: 100%;
    margin-top:7px;
}

.SidebarListHome .row {
    width: 220px;
    min-height: 50px;
    max-height: 50px;
    margin: 0px;
    align-items: center;
    justify-content: left;
    background-color: rgb(255, 255, 255);
    padding-left: 10px;
    color:#8f8f8f;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
}

.SidebarListHome .row:hover {
    cursor: pointer;
    background: #efefef;
    color:#000000;
}

.SidebarListHome #active {
    cursor: default;
    border-right-width: 4px;
    border-right-style:solid;
    border-color:#3B8FF0;
    background-color: #efefef;
    font-weight: 700; 
    color:#000000;
}

.logOutButton {
    background: #ffffff;
}

.LogoutHolder {
    height: auto;
    padding: 0;
    width: 100%;
    text-align: center;
}

.NewButton{
    background-color:  #3B8FF0;
    color:white;
    width:160px;
    margin:auto;
    padding-top:10px;
    padding-bottom:10px;
    padding-right:5px;
    font-size: 17px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    transition: all .1s ease-in;
    border-radius: 6px;
    display:"flex"
}

.NewButton:hover{
    cursor: pointer;
    box-shadow: 0px 0px 8px rgb(170, 170, 170);
}




.SPopContent {
    opacity: 100%;
    background-color: rgb(255, 255, 255);
    width: 590px;
    height: 700px;
    position: fixed;
    left: 40%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding:10px;
    box-shadow: 0px 0px 5px rgb(196, 196, 196);
    border-radius:6px;
    z-index:10000px;
}

.SPopCard {
    height: 630px;
    overflow-y: scroll;
    width:stretch;
}

.Close {
    float: right;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.Close:hover {
    color:rgb(90, 90, 90);
    cursor:pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.SidebarRight {
    top:0%;
    position:fixed;
    right:0;
    z-index: 0;
    text-align: left;
    padding-top: 40px;
    padding-left: 0px;
    height:100vh;
    min-width: 375px;
    max-width: 375px;
    border-left-style: solid;
    border-width: 1px;
    border-color: #e4e4e4;
    background-color: #ffffff;
    overflow-y: scroll;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.RightScrollContainer{
    margin-top:70px;
    margin-bottom:100px;
}


.PlaceHolderHome2 {
    height:30px;
    width: 350px;
    background-color: #ffffff;
}

.RightNavButtons{
    display:grid;
    background-color: #ffffff;
    position: fixed;
    width: 374px;
    margin-top: 27px;
}

.RightBtn{
    padding: 10px;
    width:50px;
    height:50px;
    margin-top:10px;
    margin-left:10px;
    border-radius: 100px;
    z-index: 1200;
    display: flex;
    justify-content:center;
    align-items: center;
}

.RightBtn:hover{
    background-color: #ececec;
    cursor: pointer;
}

.RightBtnSelected{
    padding: 10px;
    width:50px;
    height:50px;
    margin-top:10px;
    margin-left:10px;
    border-radius: 100px;
    background-color: #bfe2f3;
    display: flex;
    justify-content:center;
    align-items: center;
}






.collapseRight{
    position:fixed;
    right:0;
    margin-right: 10px;
    margin-top:10px;
}

.collapseRight:hover{
    color:gray;
    cursor: pointer;
}



.Home {
    display:flex;
    /* flex-wrap: wrap; */
}

.HomeContent{
    margin:auto;
}

.HomeTopRow {
    display:flex; 
    align-items: center;
}

.HomeMiddleRow {
}

.AllActivities {
    margin-top: 46px;
}

.Activities{

}

.Activities .Top {
    display:flex;
    padding:10px;
    font-size:15px;
    color:#848484;
    font-weight: 300;
}

.Profile {
   
}

.ActivitiesList{
    height:fit-content;
}

.Activity {
    margin:15px;
    padding:10px;
    display:flex;
    align-items: center;
}

.Activity .TypeIcon {
    background-color: #eeeeee;
    min-width:50px;
    min-height:50px;
    max-width:50px;
    max-height:50px;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
}

.Activity .ContentText {
    margin-left:20px;
    font-weight: 600;
}

.ToDoRow {
    padding-left:20px;
    padding-top:20px;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: rgb(227, 227, 227);
}

.BulletRow{
    margin-bottom: 5px;
    color: rgb(149, 38, 38);
}

.ToDoLink {
    text-align: center;
    font-weight: 500;
}

.ToDoLink:hover {
    color: #8f8f8f;
    cursor: pointer;
}


.CSidebarRight {
    top:0%;
    position:fixed;
    right:0;
    z-index: 0;
    text-align: left;
    padding-top: 40px;
    padding-left: 0px;
    height:100vh;
    min-width: 65px;
    max-width: 65px;
    border-left-style: solid;
    border-width: 1px;
    border-color: #e4e4e4;
    background-color: #ffffff;
    overflow-y: scroll;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.PlaceHolderHome3 {
    height:30px;
    width: 65px;
    background-color: #ffffff;
}