.Intake {
    min-width: 100vh;
    min-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top:70px;
}

.AddPerson{
    background-color: rgb(103, 174, 224);
    width:90px;
    height:40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}

.AddPerson:hover{
    background-color: rgb(89, 150, 193);
    cursor: pointer;
}

.q1Item{
    display:flex;
    background-color: rgb(228, 228, 228);
    margin:3px;
    margin-bottom:10px;
    width:stretch;
    height: 40px;
    align-items: center;
    padding:2px;
    border-radius: 10px;
    transition: height 1s;
    -moz-transition: height .5s;
    -ms-transition: height .5s;
    -o-transition: height .5s;
    -webkit-transition: height .5s;
    transition: height .5s;
}

.q1ItemExpanded{
    background-color: rgb(228, 228, 228);
    margin:3px;
    margin-bottom:10px;
    width:stretch;
    min-height: 40px;
    align-items: center;
    padding:2px;
    border-radius: 10px;
    display:grid;
}

.qSub{
    display:flex;
    background-color: rgb(249, 211, 211);
    margin:3px;
    margin-bottom:10px;
    width:stretch;
    height: 40px;
    align-items: center;
    padding:2px;
    border-radius: 10px;
}

.RemButton{
    color:white;
    background-color: rgb(224, 103, 103);
    width:100px;
    height:24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin:auto;
    margin-left:auto;
    margin-right:10px;
}

.RemButton:hover{
    background-color: rgb(192, 88, 88);
    cursor: pointer;
}

.TopOpts{
    display: flex;
    margin-bottom: 10px;
    width: stretch;
}

.TopButton {
    color:white;
    height:40px;
    width:120px;
    background-color: rgb(81, 159, 218);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    margin:auto;
}

.TopButtonDisabled {
    color: white;
    height: 40px;
    width: 120px;
    background-color: rgb(188, 188, 188);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    margin: auto;
}

.TopButton:hover {
    cursor: pointer;
    background-color: rgb(72, 142, 195);
}

.AddSubstance{
    background-color: rgb(173, 173, 173);
    width:170px;
    height:40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 500px;
}

.AddSubstance:hover{
    background-color: rgb(171, 171, 171);
    cursor: pointer;
}

.SavePerson{
    background-color: rgb(103, 174, 224);
    width:130px;
    height:40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}

.SavePerson:hover{
    background-color: rgb(89, 150, 193);
    cursor: pointer;
}

.RemCircle{
    color:white;
    background-color: rgb(224, 103, 103);
    width:25px;
    height:25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 500px;
    margin-left:10px;
    margin-right:10px;
}

.RemCircle:hover{
    background-color: rgb(192, 88, 88);
    cursor: pointer;
}


.SubBtn1{
    color:rgb(0, 0, 0);
    width:200px;
    height:25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left:auto;
}

.SubBtn1:hover{
    color: rgb(99, 99, 99);
    cursor: pointer;
}

.SubBtn2{
    color:rgb(253, 97, 97);
    width:200px;
    height:25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left:auto;
}

.SubBtn2:hover{
    color: rgb(240, 137, 137);
    cursor: pointer;
}


.AccRemButton{
    color:white;
    background-color: rgb(224, 103, 103);
    width:100px;
    height:24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin:auto;
    margin-left:auto;
    margin-right:10px;
}

.AccRemButton:hover{
    background-color: rgb(192, 88, 88);
    cursor: pointer;
}


.AddOther{
    background-color: rgb(182, 182, 182);
    width:90px;
    height:40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 500px;
}

.AddOther:hover{
    background-color: rgb(169, 169, 169);
    cursor: pointer;
}

.pac-container:after {
    background-image: none !important;
    height: 0px;
    padding: 0;
    margin: 0;
}

.addyFill {
    height:300px
}

.AddressContainer {
    display:flex;
    text-align: left;
}

.EditAccordion{
    display:flex;
    margin-left:auto;
    margin-right: 15px;
    color:rgb(77, 77, 77);
}

.EditAccordion:hover{
    color:rgb(0, 0, 0);
    cursor: pointer;
}

.NavFiller {
    height: 80px;
    width:20px;
    background-color: red;
}

.SidebarIntake {
    position:fixed;
    box-shadow: 0px 0px 5px rgb(220, 220, 220);
    top:0;
    left:0;
    height:100vh;
    width:200px;
    background-color: rgb(255, 255, 255);
    padding-top: 80px;
}

.SIList {
    padding-top: 20px;
    padding-bottom: 80px;
    height:inherit;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.SIRow {
    display: flex;
    height: 50px;
    background-color: rgb(255, 255, 255);
    -moz-transition: all .1s ease-in;
    -o-transition: all .1s ease-in;
    -webkit-transition: all .1s ease-in;
    transition: all .1s ease-in;
}


.SIRow:hover {
    background-color: rgb(235, 235, 235);
    cursor: pointer;
    -moz-transition: all .1s ease-in;
    -o-transition: all .1s ease-in;
    -webkit-transition: all .1s ease-in;
    transition: all .1s ease-in;
}

.SIRowSelected {
    display: flex;
    height: 50px;
    background-color: rgb(235, 235, 235);
    border-right-style: solid;
    border-width: 3px;
    border-color: rgb(194, 194, 194);
}

.IntakeButtons {
    display:flex;
    margin:auto;
    /* background-color: rgb(237, 237, 237); */
    border-bottom-style: solid;
    border-color: rgb(217, 217, 217);
    border-width: 1px;
    width: stretch;
    height: 60px;
}

.progBarIntake {
    width:80vw;
    height:20px;
    background-color: rgb(220, 220, 220);
}

.progBarIntakeCompleted {
    width: 80vw;
    height: 12px;
    background-color: green;
}

.QuestionCard {
    margin: auto;
    padding-bottom: 10px;
    border-color:white;
    margin-top:-72px;
    box-shadow: 0px 0px 3px rgb(195, 195, 195);
    border-radius: 0px;
    margin-bottom: 50px;
}