.TopbarLinks {
    display:flex;
    height:50px;
    margin-right:auto;
}

.TopBarIn {
    /* padding-left:-100%;
    padding-right:0%; */
    padding-left:20px;
    padding-right:20px;
    position:fixed;
    top:-1.7%;
    left:0%;
    width:100vw;
    height:69px;
    padding-top:31px;
    padding-bottom:15px;
    /* box-shadow: 0px 0px 5px rgb(226, 226, 226); */
    border-bottom-style:solid;
    border-bottom-width: 1px;
    border-bottom-color: #e8e8e8;
    
}

.navPlaceHolder {
    height: 63px;
    background-color: #ffffff;
}

.TopbarLinks #active {
    color:rgb(0, 0, 0);
}

.Nav.Link .button {
    background-color: #6da3e2;
}

.Signup {
    color:rgb(255, 255, 255);
}

.TLLogo {
    width:37px;
    height:37px;
    margin-right:7px;
    margin-left:0px;
    margin-top:-1.5px;
}

.TopInButton{
    align-items: center;
    font-size: 15px;
    align-content: center;
    justify-content: center;
    display: flex;
    border-radius: 6px;
    color:#242424;
    margin-right:-6px;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    height:40px;
    min-width:20px;
    margin-top: 3px;
    margin-right: 20px;
}

.TopInButtonSelected{
    align-items: center;
    font-size: 15px;
    align-content: center;
    justify-content: center;
    display: flex;
    border-radius: 6px;
    color:#797979;
    margin-right:-6px;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    height:40px;
    min-width:20px;
    margin-top: 3px;
    margin-right: 20px;
}


.TopInButton:hover {
    cursor: pointer;
    -moz-transition: all .1s ease-in;
    -o-transition: all .1s ease-in;
    -webkit-transition: all .1s ease-in;
    transition: all .1s ease-in;
    color:#a0a0a0;
}

.TopInButton #active{
    background: rgb(159, 159, 159);
    color: #3B8FF0;
}

.TopImg {
    width:auto;
}

.LogOutButton{
    align-items: center;
    font-size: 15px;
    align-content: center;
    justify-content: center;
    display: flex;
    padding:7px;
    border-radius: 6px;
    background-color: #3B8FF0;
    padding-left: 18px;
    padding-right: 18px;
    margin-right:-6px;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    height:40px;
    min-width:130px;
}

.LogOutButton:hover {
    cursor: pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #347fd4;
}

.ProfileContainer {
    text-align: center;
    justify-content: center;
    align-content: center;
    background-color: #ffffff;
    min-width: 500px;
    max-width: 500px;
    height: fit-content;
    padding: 20px;
    border-radius: 8px;
    filter: drop-shadow(3px 3px 3px #b6b6b6);
    min-height: 300px;
    margin: auto;
}

.profileIcon {
    display:flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width:40px;
    min-height:40px;
    max-width:40px;
    max-height:40px;
    border-radius: 100%;
    font-size: 26.5px;
    font-weight: 260;
    padding-bottom:30px;
    color:rgb(255, 255, 255);
    margin-right:21px;
}

.Profile {
    display: flex;
    align-items: center;
    margin:auto;
    color: #242424;
    width:200px;
}

.Profile:hover {
    cursor:pointer;
}

.ProfileOptions {
    z-index: 1000;
    background-color: rgb(255, 255, 255);
    width:200px;
    margin-top:48px;
    margin-left:0px;
    height:295px;
    right:inherit;
    position:absolute;
    border-radius: 10px;
    box-shadow: rgba(112, 112, 112, 0.24) 0px 0px 8px;
}

.ProfileOpen {
    margin:auto; 
}

.AccountOpt {
    width:stretch;
    height:30px;
    padding-left:20px;
    align-items: center;
    display: flex;
}

.AccountOpt:hover {
    background-color: #eeeeee;
    cursor:pointer;
}

.AccountOptOverlay {
    opacity: 80%;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}




.SearchBarPH {
    margin:auto;
    padding-left:98px;
    width: 500px;
    min-width: 400px;
    margin-top:5px;
}

.SearchInput {
    width: inherit;
    height:38px;
    border-radius:100px;
    background-color: rgb(235, 235, 235);
    border-style: none;
    padding-left: 40px;
}

.SearchIconOverlay {
    position:absolute;
    top:31%;
    margin-left:10px;
}


.SearchDropDown {
    z-index: 300;
    background-color: rgb(255, 255, 255);
    width:inherit;
    margin-top:0px;
    margin-left:0px;
    max-height:295px;
    position:absolute;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: rgba(112, 112, 112, 0.24) 0px 5px 8px;
}

.SearchList{
    height:inherit;
    max-height:295px;
    overflow-y: scroll;
}

.SearchRow {
    padding-top:13px;
    padding-bottom:13px;
    padding-left:20px;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: rgb(227, 227, 227);
    display: flex;
}

.SearchRow:hover {
    background-color: rgb(240, 240, 240);
    cursor: pointer;
}

.SearchOverlay {
    opacity: 80%;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 200;
}

.GoBack{

}

.GoBack:hover{
    color:rgb(80, 80, 80);
    cursor: pointer;
}





.NotificationWindow {
    z-index: 1000;
    background-color: rgb(255, 255, 255);
    width:350px;
    margin-top:50px;
    margin-left: -210px;
    height:350px;
    right:inherit;
    position:absolute;
    border-radius: 10px;
    box-shadow: rgba(112, 112, 112, 0.24) 0px 0px 8px;
}

.NotificationOpen {
    margin:auto; 
}

.NotificationRow {
    width:stretch;
    height:fit-content;
    padding-top:10px;
    padding-bottom:5px;
    padding-left:15px;
    padding-right:15px;
    border-bottom-style: solid;
    border-color: rgb(234, 234, 234);
    border-width: 1px;
    display: flex;
}

.NotificationOverlay {
    opacity: 80%;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.closeNotification {
    display:flex;
    border-radius: 100px;
    
}

.closeNotification:hover {
    color:gray;
    cursor: pointer;
}

.ViewNotif {
    margin-top:10px;
    padding-left:auto;
    display: flex;
    color: #347fd4;
}

.ViewNotif:hover {
    color: #3668a1;
    cursor: pointer;
}

.NotifCount {
    background-color: red;
    color:white;
    width:16px;
    height:16px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    position:absolute;
    margin-left:10px;
    margin-top:-10px
}

.NotifViewer {
    
}

.NotifViewer:hover {
    cursor: pointer;
    color: #797979;
}