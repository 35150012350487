/* Input style */
.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color:#cccccc;
  width:inherit;
}

.input-container:focus-within label {
  transform: translate(-5px, -8px) scale(0.9);
  background-color: white;
  padding-right:8px;
  padding-left:8px;
  color: #6e9fe3;
}

.input-container .filled {
  transform: translate(-5px, -8px) scale(0.9);
  padding-right:8px;
  padding-left:8px;
  background-color: white;
}

.input-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 12px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #8b8b8b;
  font-size: 16px;
  line-height: 1;
  left: 16px;
}

.input-container input {
  height: 18px;
  border-radius: 4px;
  border: none;
  padding: 21px 16px 20px 16px;
  font-size: 16px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input-container input:focus {
  box-shadow: 0 0 0 2px #79b1ff;
}























/* Input style */
.drop-input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
}

.drop-input-container:focus-within label {
  transform: translate(-5px, -10px) scale(0.9);
  background-color: white;
  padding-right:8px;
  padding-left:8px;
  color: #6e9fe3;
}

.drop-input-container .filled {
  transform: translate(-5px, -10px) scale(0.9);
  padding-right:8px;
  padding-left:8px;
  background-color: white;
}

.drop-input-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 9px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #8b8b8b;
  font-size: 16px;
  left: 16px;
}

.drop-input-container input {
  border-radius: 4px;
  border: none;
  padding: 24px 16px 19px 16px;
  font-size: 16px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.drop-input-container input:focus {
}




























/* Input style */
.phone-input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border-color:#cccccc;
}

.phone-input-container:focus-within label {
  transform: translate(0, -12px) scale(0.9);
  background-color: white;
  padding-right:8px;
  padding-left:8px;
  color: #6e9fe3;
}

.phone-input-container .filled {
  transform: translate(0, -12px) scale(0.9);
  padding-right:8px;
  padding-left:8px;
  background-color: white;
}

.phone-input-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(45px, 9px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #8b8b8b;
  left: 16px;
}

.phone-input-container input {
  height: 18px;
  border-radius: 4px;
  border: none;
  padding: 24px 16px 18px 16px;
  font-size: 16px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.phone-input-container input:focus {
  box-shadow: 0 0 0 2px #79b1ff;
}



.AddressInputOverlay {
  position:absolute;
  overflow-y: hidden;
  z-index: 999;
  width:30px;
}

.AddressInputDropdown {
  height:fit-content;
  left: 0px;
  right: 0px;
  top:110%;
  background-color:white;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  position:absolute;
  z-index: 999;
  box-shadow: 0px 2px 5px rgb(193, 193, 193);
}

.AddressItem {
  border-bottom-style:solid;
  border-color:rgb(225, 225, 225);
  padding:10px;
  display:flex;
  color:rgb(52, 52, 52);
  height:45px;
  text-align: left;
}

.AddressItem:hover {
  cursor: pointer;
  background-color: #ececec;
}

.AddressClear {
  text-align: center;
  padding-left:5px;
  position:absolute;
  right:10px;
  top:7px;
  color:gray;
  z-index: 1000;
  width:30px;
  height:30px;
  background-color: rgb(255, 255, 255);
}

.AddressClear:hover {
  cursor:pointer;
}