.EditMatter {
    display:flex;
    background-color: #fafafa;
    min-height:100%;
    
    /* flex-wrap: wrap; */
}

.PageDisplay {
    width:100%;
    margin:auto;
    margin-top:-50px;
    padding-top: 60px;
    padding-left: 50px;
    padding-right: 50px;
    height:100%;
    overflow-y: scroll;
    height:97vh;
}

.EditContainer {
    height: fit-content;
    margin: auto;
    margin-top:60px;
}

.BackButton{
    background-color: rgb(236, 236, 236);
    align-items: center;
    font-size: 15px;
    align-content: center;
    justify-content: center;
    display: flex;
    padding:7px;
    border-radius: 6px;
    color: black;
    padding-left: 18px;
    padding-right: 18px;
    margin-right:-6px;
    width:fit-content;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.BackButton:hover {
    cursor: pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    background: #c8c8c8; 
    color:#000000;
}

.MatterTop {
    display: flex;
    align-items: center;
}

.MatterLocation {
    padding-left:50px;
}

.MatterOptions {
    padding-top: 8px;
}

.MatterOpt1 {
    display: flex;
    align-items: center;
    padding: 15px;
}

.MatterOpt2 {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #f0f0f0;
}

.ViewMatterButton{
    border-style: solid;
    flex-direction: r;
    align-items: center;
    font-size: 15px;
    display: flex;
    padding:7px;
    border-radius: 6px;
    color: #3B8FF0;
    border-width: 1px;
    padding-left: 18px;
    padding-right: 18px;
    margin-right: 10px;
    margin-left:auto;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}


.ViewMatterButton:hover {
    cursor: pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    background: #b8d2f1;
}
