.SharedList {
  padding-left:20px;
  padding-right:20px;
  height:320px;
  margin-bottom:-24px;
  overflow-y: scroll;
}

.FirmSettingsPage {
  display:flex;

}

.EditFirm {
  margin:auto;
  margin-top: 40px;
}

.EditFirmTop {
  display:flex;
  margin:auto;
}

.SharedRowF {
  background-color: white;
  display: flex;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(200, 200, 200);
  width: stretch;
  padding: 13px;
  border-radius: 5px;
  margin-bottom:6px;
  align-items: center;
  max-height: 45px;
}

.Remove {
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  margin-left:auto;
  -moz-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
}

.Remove:hover {
  color: rgb(151, 151, 151);
  cursor: pointer;
  -moz-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
}

.actions {
margin-left:auto;
}

.topCard {
  width:510px;
  height: 420px;
  padding: 5px;
  margin-right:10px;
  margin-left:10px;

}

.topCard .topTop {
  margin:auto;
  margin-bottom:20px;
}

.topCard .topTop .Priv{
  display:flex;
}


.btmCard {
  width:510px;
  height: 420px;
  margin:auto;
  padding:20px;
  padding-left:40px;
  padding-right:40px;
}

.btmCard .AllTop {
  display:flex;
  margin-top:9px;
}

.btmCard .AllTop .btmTop {
  display: flex;
  text-align:center;
  margin:auto;
}

.btmCard .AllTop .InfoEdit {
  display: flex;
}

.btmCard .AllTop .InfoEdit {
  background-color: rgb(255, 255, 255);
  border-radius: 3px;
  height:35px;
  padding-left:5px;
  padding-right:5px;
  margin-left:20px;
  display:flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom:4px;
  margin-top:-4px;
  -moz-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
}

.btmCard .AllTop .InfoEdit:hover {
  cursor:pointer;
  color: rgb(122, 122, 122);
  -moz-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
}

.FirmLoading {
  display:flex;
  justify-content: center;
  color:antiquewhite;
  margin-top: 200px;
}

.editCard {
  width: 500px;
  padding:20px;
  padding-left:50px;
  padding-right:50px;
}

.editCard .AllTop {
  text-align: center;
  margin:auto;
}

.editCard .AllTop .btmTop {
  display: flex;
  text-align:center;
}

.editCard .AllTop .InfoEdit {
  display: flex;
}

.editCard .AllTop .InfoEdit {
  background-color: rgb(222, 222, 222);
  border-radius: 3px;
  height:35px;
  padding-left:5px;
  padding-right:5px;
  margin-left:20px;
  display:flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom:4px;
  margin-top:-4px;
  -moz-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
}

.editCard .AllTop .InfoEdit:hover {
  cursor:pointer;
  background-color: rgb(183, 183, 183);
  -moz-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
}



.topCard .PendingButton {
  background-color: rgb(222, 222, 222);
  border-radius: 3px;
  height:35px;
  padding-left:5px;
  padding-right:5px;
  display:flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom:4px;
  margin-top:-4px;
  -moz-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
}

.topCard .PendingButton:hover {
  background-color: rgb(183, 183, 183);
  -moz-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
  cursor:pointer;
}