.appIcon{
    width: 100px;
    height: 100px;
    border-radius: 5%;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 20px;
    /* give the app a glossy overtone */
    background-image: linear-gradient(rgba(255,255,255,0.5), rgba(230, 230, 230, 0.5)), radial-gradient(circle at 50% 150%, rgba(255,255,255,0.2), rgba(0,0,0,0));
}

.appIcon:hover{
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.4);
    cursor: pointer;
}