.Sidebar {
    position: fixed;
    height:100vh;
    background-color: #ffffff;
    text-align: left;
    padding-top: 18px;
    padding-left: 0px;
    max-height:100%;
    min-width: 225px;
    max-width: 225px;
    border-right-style:solid;
    border-right-width: 1px;
    border-right-color: #e8e8e8;
    top:0;
    -moz-transition: all .1s ease-in;
    -o-transition: all .1s ease-in;
    -webkit-transition: all .1s ease-in;
    transition: all .1s ease-in;
    padding-top:90px;
}

.PlaceHolder1 {
    text-align: left;
    padding-top: 18px;
    padding-left: 0px;
    max-height:100%;
    width: 225px;
}

.CSidebar {
    position: fixed;
    height:100vh;
    transition: all .1s ease-in;
    background-color: #ffffff;
    text-align: left;
    padding-top: 18px;
    padding-left: 0px;
    max-height:100%;
    min-width: 70px;
    max-width: 70px;
    border-right-style:solid;
    border-right-width: 1px;
    border-right-color: #e8e8e8;
    top:0;
    padding-top:90px;
}

.PlaceHolder2 {
    text-align: left;
    padding-top: 18px;
    padding-left: 0px;
    max-height:100%;
    min-width: 70px;
    max-width: 70px;
}



.Top {
    justify-content: center;
    display:inline;
}

.Collapse {
    float: right;
    padding-right: 20px;
    padding-bottom: 20px;
}


.Top2 {
    justify-content: center;
    display:inline;
}

.Expand {
    float: right;
    padding-right: 24px;
    padding-bottom: 20px;
}


body {
    margin: 0;
    padding: 0;
}

.Sidebar h1 {
    resize: none;
    font-size: 2em;
    resize: none;
}

.Sidebar p {
    padding-left: 20px;
}

img {
    width: 200px;
    height: 45px;
    align-items: center;
    margin-left: 40px;
}

.SidebarList {
    height: auto;
    padding: 0;
    width: 100%
}

.SidebarList .row {
    width: 224px;
    min-height: 50px;
    max-height: 50px;
    margin: 0px;
    align-items: center;
    justify-content: left;
    background-color: rgb(255, 255, 255);
    padding-left: 10px;
    color:#8f8f8f;
}

.SidebarList .row:hover {
    cursor: pointer;
    background: #e9e9e9; 
    color:#000000;
}

.SidebarList #active {
    cursor: default;
    border-right-width: 4px;
    border-right-style:solid;
    border-color:#3B8FF0;
    font-weight: 700; 
    color:#000000;
}

.SidebarList .row #active {
    background: #beb7ff; 
    cursor: default;
    border-radius: 20px;
}

.logOutButton {
    background: #ffffff;
}

.LogoutHolder {
    height: auto;
    padding: 0;
    width: 100%;
    text-align: center;
}

.LogoutHolder .row {
    width: 100%;
    height: 50px;
    list-style-type: none;
    margin: 0px;
    align-items: center;
    justify-content: left;
    background-color: rgb(236, 236, 236);
    padding-right: 20px;
    color:#000000;
}

.LogoutHolder .row:hover {
    cursor: pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    background: #c8c8c8; 
}

.CLogoutHolder {
    height: auto;
    padding: 0;
    width: 100%;
    text-align: center;
}

.CLogoutHolder .row {
    width: 100%;
    height: 50px;
    list-style-type: none;
    margin: 0px;
    align-items: center;
    justify-content: left;
    background-color: rgb(236, 236, 236);
    padding-right: 3px;
    color:#000000;
}

.CLogoutHolder .row:hover {
    cursor: pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    background: #c8c8c8; 
}


.CSidebarList {
    height: auto;
    padding: 0;
    width: 100%
}

.CSidebarList .row {
    width: 100%;
    min-height: 50px;
    max-height: 50px;
    list-style-type: none;
    margin: 0px;
    align-items: center;
    justify-content: left;
    background-color: rgb(255, 255, 255);
    padding-left: 10px;
    color:#8f8f8f;
}

.CSidebarList .row:hover {
    cursor: pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    background: #dedede; 
    color:#000000;
}

.CSidebarList #active {
    cursor: default;
    border-right-width: 4px;
    border-right-style:solid;
    border-color:#3B8FF0;
    font-weight: 700; 
    color:#000000;
}

.CSidebarList .row #active {
    background: #beb7ff; 
    cursor: default;
    border-radius: 20px;
}
