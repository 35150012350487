.SharedList {
    padding-left:20px;
    padding-right:20px;
}

.PlaintiffPage {
    width:70%;
    justify-content: center;
    margin:auto;
    flex-wrap: wrap;
} 

.SharedRow {
    background-color: white;
    display: flex;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(200, 200, 200);
    width: stretch;
    padding: 13px;
    border-radius: 5px;
    margin-bottom:6px;
}

.Remove {
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    margin-left:auto;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.Remove:hover {
    color: rgb(151, 151, 151);
    cursor: pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.overlay {
    background-color:white;
    opacity: 80%;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.FPopContent {
    opacity: 100%;
    background-color: rgb(255, 255, 255);
    width: 590px;
    height: 700px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding:10px;
    box-shadow: 0px 0px 5px rgb(196, 196, 196);
    border-radius:6px;
    z-index:10000;
}

.APopCard {
    height: 630px;
    overflow-y: scroll;
    width:stretch;
}

.Close {
    float: right;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.Close:hover {
    color:rgb(90, 90, 90);
    cursor:pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}


.PlaintiffView {
    background-color: rgb(222, 222, 222);
    border-radius: 3px;
    padding:4px;
    display:flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left:auto;
}

.PlaintiffView:hover {
    cursor:pointer;
    background-color: rgb(183, 183, 183);
}

.PlaintiffRow {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(200, 200, 200);
    width: stretch;
    padding: 13px;
    border-radius: 5px;
    margin-bottom:6px;
}

.PEditTop {
    padding-top:5px;
    display: flex;
}

.PlaintiffEdit {
    background-color: rgb(222, 222, 222);
    border-radius: 3px;
    padding:4px;
    display:flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left:10px;
    margin-top:-2px;
    margin-bottom:2px;
}

.PlaintiffEdit:hover {
    cursor:pointer;
    background-color: rgb(183, 183, 183);
}

.SaveBtn1 {
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(212, 212, 212);
    border-radius: 4px;
    padding:5px;
    width:90px;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.SaveBtn1:hover {
    background-color:rgb(178, 178, 178);
    cursor:pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.CancelBtn1 {
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(212, 212, 212);
    border-radius: 4px;
    padding:5px;
    width:90px;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.CancelBtn1:hover {
    background-color: rgb(178, 178, 178);
    cursor:pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.DelBtn {
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(230, 165, 165);
    border-radius: 4px;
    padding:5px;
    width:160px;
    margin:auto;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.DelBtn:hover {
    background-color: rgb(205, 146, 146);
    cursor:pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}


.SaveBtnDisabled {
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(183, 198, 232);
    border-radius: 4px;
    color:rgb(145, 145, 145);
    padding:5px;
    width:90px;
}

.FirmsTop {
    text-align: center;
    display: flex;
    margin: auto;
}

.FirmsTop .Add {
    background-color: rgb(35, 172, 47);
    border-radius: 2px;
    padding: 3px;
    padding-top: 2px;
    padding-left:4px;
    padding-right:4px;
    margin-left: 20px;
    margin-top: -4px;
    margin-bottom:20px;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.FirmsTop .Add:hover {
    background-color: rgb(32, 141, 41);
    cursor: pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}
