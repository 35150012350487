.Dashboard {
    background-color: #ffffff;
}

.itemBox {
    background-color: #ffffff;
    padding-left: 0px;
    height: 20vh;
    width: 200px;
    text-align: center;
    border-radius: 5%;
    position: fixed;
    top: 0;
    right: 0;
    margin-right: 500px;
    margin-top: 50px;
}

body {
    margin: 0;
    padding: 0;
}

.Dashboard h1 {
    resize: none;
    font-size: 2em;
    resize: none;
}